<template>
  <div class="login-container">
    <div class="bg">
      <img src="@/assets/icon/login-bg.png" alt="">
    </div>
    <el-form v-show="isPsd" ref="loginForm" :model="loginForm" :rules="loginRule" class="login-form" :style="style" auto-complete="on" label-position="left">
      <div class="change-login" @click="isCode=!isCode">
        <img v-if="!isCode" src="@/assets/icon/ecode.png" alt="">
        <img v-if="isCode" src="@/assets/icon/return-ecode.png" alt="">
      </div>
      <div class="title-container">
        <svg-icon icon-class="title" class-name="svg" class="title" />
      </div>
      <div v-if="!isCode" class="title-type">
        <span class="active" style="margin-right:20px" @click="loginType">密码登录</span>
        <span @click="loginType">短信登录</span>
      </div>
      <div v-if="!isCode">
        <!-- <div v-if="!isShowCode" class="space" /> -->
        <el-form-item prop="account" :show-message="showAccountTip">
          <div class="bg" />
          <el-input
            ref="account"
            v-model="loginForm.account"
            placeholder="请输入账号"
            name="account"
            type="text"
            tabindex="1"
            auto-complete="on"
            @blur="accountBlur"
          >
            <template #prefix>
              <svg-icon icon-class="user" class-name="svg" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" :show-message="showPasswordTip">
          <div class="bg" />
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter="handleLogin"
            @blur="passwordBlur"
          >
            <template #prefix>
              <svg-icon icon-class="password" class-name="svg" />
            </template>
          </el-input>
          <span class="show-pwd" @click="showPwd">
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span>
        </el-form-item>
        <!-- <div v-if="!isShowCode" class="space" /> -->
        <el-form-item v-if="isShowCode" prop="code" :show-message="showCodeTip" class="code-box">
          <div class="bg" />
          <el-input
            v-model="loginForm.code"
            placeholder="请输入图片验证码"
            @keyup.enter="handleLogin"
            @blur="codeBlur"
          >
            <template #prefix>
              <svg-icon icon-class="image-code" class-name="svg" />
            </template>
          </el-input>
          <img class="image" :src="image" alt="" @click="refresh()">
        </el-form-item>
        <div class="about-handle">
          <el-checkbox v-model="isAgree" @change="isAgreeChange">我已阅读并同意<router-link target="_blank" to="/userAgreement">平台用户服务协议</router-link></el-checkbox>
          <div class="forget-password" @click="register">免费注册</div>
          <div class="forget-password" @click="forgetPassword">忘记密码？</div>
        </div>
        <el-button :disabled="btnDisabled" :loading="loading" type="primary" style="width:100%;" @click.prevent="handleLogin">登录</el-button>
      </div>
      <div v-if="isCode" class="ecode">
        <wxlogin />
        <div class="tips" style="margin-top: 30px">微信扫码即可快速登录云桌面</div>
        <div class="tips">扫码登录视为您已阅读并同意<router-link target="_blank" to="/userAgreement">平台用户服务协议</router-link></div>
      </div>
      <div class="download">
        <div class="download-title">下载客户端</div>
        <div class="download-icons">
          <!--  -->
          <svg-icon icon-class="win" class-name="svg" @click="downloadExe('win')" />
          <svg-icon icon-class="mac" class-name="svg" @click="downloadExe('mac')" />
          <svg-icon icon-class="linux" class-name="svg" @click="loadUrl" />

        </div>
      </div>
    </el-form>
    <!-- 验证码登录 -->
    <el-form v-show="!isPsd" ref="loginForms" :model="loginForms" :rules="loginRules" class="login-form" :style="style" auto-complete="on" label-position="left">
      <div class="change-login" @click="isCode=!isCode">
        <img v-if="!isCode" src="@/assets/icon/ecode.png" alt="">
        <img v-if="isCode" src="@/assets/icon/return-ecode.png" alt="">
      </div>
      <div class="title-container">
        <!-- <div class="title">云桌面</div> -->
        <svg-icon icon-class="title" class-name="svg" class="title" />
      </div>
      <div v-if="!isCode" class="title-type">
        <span style="margin-right:20px" @click="loginType">密码登录</span>
        <span class="active" @click="loginType">短信登录</span>
      </div>
      <div v-if="!isCode">
        <el-form-item prop="account" :show-message="showAccountTip">
          <div class="bg" />
          <el-input
            ref="account"
            v-model="loginForms.account"
            placeholder="请输入手机号"
            name="account"
            type="text"
            tabindex="1"
            auto-complete="on"
            @blur="accountsBlur"
          >
            <template #prefix>
              <svg-icon icon-class="phone" class-name="svg" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="code" :show-message="showCodeTip" class="code-box">
          <div class="bg" />
          <el-input
            v-model="loginForms.code"
            placeholder="请输入图片验证码"
            @keyup.enter="handleLogin"
            @blur="codesBlur"
          >
            <template #prefix>
              <svg-icon icon-class="image-code" class-name="svg" />
            </template>
          </el-input>
          <img class="image" :src="image" alt="" @click="refresh()">
        </el-form-item>
        <el-form-item prop="phoneCode" :show-message="showPhoneCodeTip">
          <div class="bg" />
          <el-input
            v-model="loginForms.phoneCode"
            placeholder="请输入手机验证码"
            @keyup.enter="handleLogin"
            @blur="phoneCodeBlur"
          >
            <template #prefix>
              <svg-icon icon-class="password" class-name="svg" />
            </template>
            <template #suffix>
              <span class="border-style" />
              <svg-icon icon-class="msg" class-name="svg" />
              <span v-show="showMsg" class="send-msg" @click="sendMsgHandle">获取验证码</span>
              <span v-show="!showMsg" class="send-msg">{{ count }} s后重新获取</span>
            </template>
          </el-input>
        </el-form-item>
        <div class="about-handle">
          <el-checkbox v-model="isAgree" @change="isAgreeChange">我已阅读并同意<router-link target="_blank" to="/userAgreement">平台用户服务协议</router-link></el-checkbox>
          <div class="forget-password" @click="register">免费注册</div>
        </div>
        <el-button :disabled="btnDisabled" :loading="loading" type="primary" style="width:100%;" @click.prevent="handleLogin">登录</el-button>
      </div>
      <div v-if="isCode" class="ecode">
        <wxlogin />
        <div class="tips" style="margin-top: 30px">微信扫码即可快速登录云桌面</div>
        <div class="tips">扫码登录视为您已阅读并同意<router-link target="_blank" to="/userAgreement">平台用户服务协议</router-link></div>
      </div>
      <div class="download">
        <div class="download-title">下载客户端</div>
        <div class="download-icons">
          <!--  -->
          <svg-icon icon-class="win" class-name="svg" @click="downloadExe('win')" />
          <svg-icon icon-class="mac" class-name="svg" @click="downloadExe('mac')" />
          <svg-icon icon-class="linux" class-name="svg" @click="loadUrl" />
        </div>
      </div>
    </el-form>
    <div class="copyright">COPYRIGHT © 山东正云信息科技有限公司 鲁ICP备20000303号</div>
  </div>
</template>

<script>
import { captcha, getLoginSms, queryLoginState } from '@/api/user'
import wxlogin from '@/components/code'
import { ElMessage } from 'element-plus'
import validate from '@/utils/baseValidate.js'
export default {
  components: { wxlogin },
  data() {
    return {
      isShowCode: false,
      style: {
        height: '615px'
      },
      btnDisabled: false,
      isAgree: true,
      showMsg: true,
      count: '',
      timer: null,
      isPsd: true,
      isCode: false,
      showCodeTip: true,
      showPhoneCodeTip: true,
      showPasswordTip: true,
      showAccountTip: true,
      autoLogin: false,
      image: '',
      keys: '',
      loginForm: {
        account: '',
        password: '',
        code: ''
      },
      loginRule: {
        account: [{ required: true, trigger: 'blur', validator: validate.loginAccount }],
        password: [{ required: true, trigger: 'blur', validator: validate.password }]
        // code: [
        //   { required: true, message: '请根据图片验证码填写验证码', trigger: 'blur' }
        // ]
      },
      loginForms: {
        account: '',
        code: '',
        phoneCode: ''
      },
      loginRules: {
        account: [{ required: true, trigger: 'blur', validator: validate.phone }],
        code: [
          { required: true, message: '请根据图片验证码填写验证码', trigger: 'blur' }
        ],
        phoneCode: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' }
        ]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    }
  },
  mounted() {
    this.refresh()
    localStorage.cLoseDialog = 'true'
    localStorage.activity = 'false'
  },
  methods: {
    isAgreeChange(val) {
      if (val) {
        this.btnDisabled = false
      } else {
        this.btnDisabled = true
      }
    },
    // 登录方式
    loginType() {
      this.isPsd = !this.isPsd
      if (!this.isPsd) {
        this.style.height = '590px'
      } else {
        if (this.isShowCode) {
          this.style.height = '590px'
        } else {
          this.style.height = '510px'
        }
      }
      this.$refs.loginForm.resetFields()
      this.$refs.loginForms.resetFields()
    },
    // 发送验证码
    sendMsgHandle() {
      const data = {
        account: this.loginForms.account.trim(),
        phone: this.loginForms.account.trim(),
        captchaKey: this.keys,
        code: this.loginForms.code.trim()
      }
      if (this.loginForms.account.trim() !== '' && this.loginForms.code.trim() !== '') {
        const TIME_COUNT = 60
        if (!this.timer) {
          this.count = TIME_COUNT
          this.showMsg = false
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.showMsg = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
        getLoginSms(data).then(res => {
          if (res.meta.status === 200) {
            ElMessage.success('发送成功')
          } else {
            ElMessage.error(res.meta.msg)
            this.refresh()
          }
        }).catch(() => {
          this.refresh()
        })
      } else {
        ElMessage.error('请填写手机号以及图片验证码！')
      }
    },
    codeBlur() {
      this.showCodeTip = Boolean(this.loginForm.code)
    },
    passwordBlur() {
      this.showPasswordTip = Boolean(this.loginForm.password)
    },
    accountBlur() {
      this.showAccountTip = Boolean(this.loginForm.account)
    },
    codesBlur() {
      this.showCodeTip = Boolean(this.loginForms.code)
    },
    phoneCodeBlur() {
      this.showPhoneCodeTip = Boolean(this.loginForms.phoneCode)
    },
    accountsBlur() {
      this.showAccountTip = Boolean(this.loginForms.account)
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    refresh() {
      captcha().then((response) => {
        this.image = response.data.image
        this.keys = response.data.key
      })
    },
    // 忘记密码
    forgetPassword() {
      const routerData = this.$router.resolve('/personal-info/find-password')
      window.open(routerData.href, '_blank')
    },
    // 免费注册
    register() {
      this.$router.push('/registerUser')
    },
    handleLogin() {
      this.showCodeTip = true
      this.showPasswordTip = true
      this.showAccountTip = true
      this.showPhoneCodeTip = true
      if (!this.isAgree) {
        ElMessage.warning('请先阅读并同意平台用户服务协议')
        return
      }
      if (this.isPsd) {
        var data
        if (this.isShowCode) {
          if (this.loginForm.code.trim() === '') {
            ElMessage.error('请输入验证码')
            return
          }
          data = {
            account: this.loginForm.account.trim(),
            password: this.loginForm.password.trim(),
            codeKey: this.keys,
            code: this.loginForm.code.trim(),
            phoneCode: ''
          }
        } else {
          data = {
            account: this.loginForm.account.trim(),
            password: this.loginForm.password.trim(),
            codeKey: '',
            code: '',
            phoneCode: ''
          }
        }
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.loading = true
            this.$store.dispatch('user/login', data).then((res) => {
              if (res.meta.status === 201) {
                this.$router.push('/desktop')
                setTimeout(() => {
                  this.loading = false
                }, 350)
              } else {
                ElMessage.error(res.meta.msg)
                this.refresh()
                queryLoginState(this.loginForm.account).then((response) => {
                  if (response.meta.status === 467) {
                    this.style.height = '590px'
                    this.isShowCode = true
                  }
                })
                setTimeout(() => {
                  this.loading = false
                }, 350)
              }
            }).catch(error => {
              console.log(error)
              this.loading = false
              if (this.isShowCode) {
                this.refresh()
              }
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        const data = {
          account: this.loginForms.account.trim(),
          password: '',
          codeKey: '',
          code: '',
          phoneCode: this.loginForms.phoneCode.trim()
        }
        this.$refs.loginForms.validate(valid => {
          if (valid) {
            this.loading = true
            this.$store.dispatch('user/login', data).then((res) => {
              if (res.meta.status === 201) {
                this.$router.push('/desktop')
                setTimeout(() => {
                  this.loading = false
                }, 350)
              } else {
                ElMessage.error(res.meta.msg)
                setTimeout(() => {
                  this.loading = false
                }, 350)
              }
            }).catch(error => {
              console.log(error)
              this.loading = false
              this.refresh()
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    async downloadExe(type) {
      var link = document.createElement('a')
      if (type === 'win') {
        link.href = process.env.VUE_APP_DOWNLOAD_URL + '/cloud-desktop-electron-win32-x64.zip'
        link.setAttribute('download', 'cloud-desktop-electron-win32-x64.zip')
      } else if (type === 'mac') {
        link.href = process.env.VUE_APP_DOWNLOAD_URL + '/cloud-desktop-electron-darwin-x64-1.0.0.zip'
        link.setAttribute('download', 'cloud-desktop-electron-darwin-x64-1.0.0.zip')
      }
      document.body.appendChild(link)
      link.style.display = 'none'
      // 模拟点击a标签
      // console.log(link)
      link.click()
      document.body.removeChild(link)
    },
    loadUrl() {
      window.open('https://linux-cli.download.hpc.jengcloud.com/linux-cli/latest/jchpc-cli')
    }
  }
}
</script>

<style lang="less" scoped>
.login-container{
  width: 100vw;
  height: 100vh;
  background-color: #F0F4FA;
  ::v-deep .el-form-item.is-error .el-input__wrapper, .el-form-item.is-error .el-input__wrapper.is-focus, .el-form-item.is-error .el-input__wrapper:focus, .el-form-item.is-error .el-input__wrapper:hover, .el-form-item.is-error .el-select__wrapper, .el-form-item.is-error .el-select__wrapper.is-focus, .el-form-item.is-error .el-select__wrapper:focus, .el-form-item.is-error .el-select__wrapper:hover, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner.is-focus, .el-form-item.is-error .el-textarea__inner:focus, .el-form-item.is-error .el-textarea__inner:hover{
    box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
  }
  ::v-deep .el-input__wrapper.is-focus{
    box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
  }
  .download{
    margin-top: 36px;
    border-top: 1px solid rgba(205,208,214,0.6);
    padding-top: 30px;
    display: flex;
    justify-content: center;
    .download-title{
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #165DFF;
      line-height: 28px;
      margin-right: 6px;

    }
    .download-icons{
      .svg-icon{
        vertical-align: -10px;
        margin-left: 24px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
  .copyright{
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 50%;
    text-align: center;
    color: #2385FF;
  }
  .svg-icon{
    vertical-align: -15px;
    width: 16px;
    height: 18px;
    margin-left: 10px;
  }
  ::v-deep .el-input__inner::placeholder {
        color: #9CA2AB !important;
    }
     /* 谷歌 */
    ::v-deep .el-input__inner::-webkit-input-placeholder {
        color: #9CA2AB !important;
    }
     /* 火狐 */
   ::v-deep .el-input__inner:-moz-placeholder {
        color: #9CA2AB;
    }
     /*ie*/
   ::v-deep .el-input__inner:-ms-input-placeholder {
        color: #9CA2AB !important;
    }
  .el-button--primary{
    width: 430px;
    height: 64px;
    background: #2385FF !important;
    box-shadow: 0px 4px 10px 0px #CFD3EB;
    border-radius: 6px;
    margin-top: 18px;
  }
  .el-button--primary.is-disabled{
    background: #A7CEFF !important;
    border-color: #A7CEFF !important;
  }
  .el-button{
    font-size: 18px;
  }
  .bg {
    // width: 1034px;
    // height: 100vh;
    // background-color: #0037e8;
    img {
      width: 50%;
      height: 100vh;
    }
  }
  .el-input {
    display: inline-block;
    // height: 47px;
    // width: 85%;
  }
  ::v-deep .el-checkbox{
    cursor: default;
  }
  ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
    color: #606266;
  }
  ::v-deep .el-form-item__content {
    line-height: 52px;
  }
  .el-form-item {
    // background: rgba(255, 255, 255, 0.2);
    // border: 1px solid #FFFFFF;
    // border-radius: 6px;
    // color: #fff;
    position: relative;
    .bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 430px;
      height: 60px;
      background: #FFFFFF;
      border: 1px solid #D8D8D8;
      border-radius: 2px;

    }
    ::v-deep .el-input__wrapper{
      width: 430px;
    }
    ::v-deep .el-input__inner{
      // background: url(../../assets/icon/input.png);
      position: relative;
      background: transparent;
      // border: 1px solid transparent !important;
      // border-radius: 6px;
      box-shadow: none;
      width: 375px;
      height: 60px;
      color: #737D85 !important;
    }
  }
  ::v-deep .el-input__suffix{
    .border-style{
      vertical-align: -12px;
      padding-left: 20px;
      border-left: 1px solid #C9CED6;
    }
    .svg-icon{
      margin-top: 23px;
    }
  }
  ::v-deep .el-input__prefix{
    top: 21px;
    left: 0;
  }
  .send-msg{
    vertical-align: -10px;
      padding: 16px 21px 0 5px;
      color: #2385FF;
      cursor: pointer;
    }
  .code-box{
    ::v-deep .el-input__wrapper{
      width: 235px;
    }
    ::v-deep .el-input__inner{
      width: 235px;
    }
    .bg{
      width: 235px;
    }
    .el-input{
      width: 235px;
    }
    img{
      width: 180px;
      height: 60px;
      margin-left: 15px;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: -5px;
    font-size: 16px;
    color: #737D85;
    cursor: pointer;
    user-select: none;
  }
  .about-handle {
          // margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .forget-password {
            color: #737d85;
            cursor: pointer;
            font-size: 14px;
          }
        }
  .login-form {
    position: absolute;
    left: 75%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
    // height: 490px;
    width: 573px;
    background: #fff;
    border: 1px solid #E3E8F0;
    box-shadow: 0px 4px 15px 0px #CFD3EB;
    border-radius: 12px;
    padding: 22px 70px;
    margin-bottom: 20px;
    // ::v-deep .el-input--prefix .el-input__inner{
    //   padding-left: 40px;
    // }
    .change-login{
      position: absolute;
      top: 15px;
      right: 15px;
      img{
        width: 76px;
        height: 76px;
      }
    }
    .ecode{
      margin-top: 20px;
      text-align: center;
      .tips{
        padding-left: 15px;
      }
      .tips:last-child{
        padding-top: 8px;
      }
    }
    .title-container{
      text-align: center;
      padding: 28px 0;
    }
    .title {
      // text-align: center;
      // padding-bottom: 38px;
      width: 114px;
      height: 40px;
      // font-size: 24px;
      // font-family: Source Han Sans SC;
      // font-weight: 500;
      // color: #2385FF;
    }
    .title-type{
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #737D85;
      margin-bottom: 30px;
      .active {
        padding-bottom: 10px;
        border-bottom: 2px solid #2385FF;
        color: #2385FF;
      }
    }
  }
  .svg-container {
    // padding: 3px 5px 3px 15px;
    padding-left: 15px;
    color: #4195f2;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }
  .tips{
    // padding-top: 40px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
  }
}
</style>
